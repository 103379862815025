@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.shadow-line {
  box-shadow: -16px 3px 349px -27px rgba(243, 196, 255, 0.83);
  -webkit-box-shadow: -16px 3px 349px -27px rgba(243, 196, 255, 0.83);
  -moz-box-shadow: -16px 3px 349px -27px rgba(243, 196, 255, 0.83);
}

.shadow-navbar {
  box-shadow: 28px 41px 42px -37px rgba(0, 155, 144, 0.75);
  -webkit-box-shadow: 28px 41px 42px -37px rgba(0, 155, 144, 0.75);
  -moz-box-shadow: 28px 41px 42px -37px rgba(0, 155, 144, 0.75);
}

.shadow-card-tagline {
  box-shadow: -1px -1px 5px 4px rgba(0, 12, 54, 0.01),
    2px 2px 2px rgba(33, 51, 96, 0.04), 4px 4px 4px 6px rgba(0, 155, 144, 0.04);
}

.shadow-side-bar-admin {
  box-shadow: 8px 0px 12px rgba(190, 190, 190, 0.08),
    6px 0px 20px rgba(190, 190, 190, 0.1);
}

.register-page {
  background: linear-gradient(
    142.04deg,
    rgba(254, 254, 254, 0) -1.93%,
    #e4f4f8 107.32%
  );
  width: 100%;
}

.text-color-green {
  color: #009b90;
}

.hover-text-green:hover {
  color: #13b3a8;
}

.background-color-green {
  background-color: #009b90;
}

.background-color-green-2 {
  background-color: #f3fbfb;
}

.background-color-green-3 {
  background-color: #f0f8fb;
}

.button-primary:hover {
  background: #447d8a !important;
}

.button-primary {
  background: #009b90 !important;
  color: white !important;
  min-width: fit-content !important;
}

.border-button:hover {
  background: #3e8d9e !important;
  color: white !important;
}

.border-button {
  background: white !important;
  color: #009b90 !important;
  border-color: #009b90;
  border-style: solid;
  border-width: 1px;
  min-width: fit-content !important;
}

.button-answered-question-admin {
  background: #c2c2c2 !important;
  color: white !important;
}

.bg-dashboard-admin {
  background: linear-gradient(
    155.7deg,
    #d6f5f3 -45.88%,
    #f7fcfc 45.77%,
    #f1f5fc 117.72%
  );
}

.logout-admin-button {
  background-color: #009b90;
  color: white;
}

.logout-admin-button:hover {
  background-color: #656767;
}

.logo-image {
  height: 24px !important;
  width: fit-content;
}

.logo-bank {
  max-height: 2rem !important;
}

.banner-homepage {
  height: 100% !important;
}

.banner-homepage-perscription {
  height: 100% !important;
  max-height: 150px !important;
}

.table-list-head {
  background-color: #12857d !important;
}

@media only screen and (min-width: 992px) {
  .logo-bank {
    max-height: 3rem !important;
  }
  .logo-image {
    height: 44px !important;
  }
}

.sidebar-width {
  width: 64px;
}
.content-width {
  width: calc(100vw - 64px);
  overflow: auto;
}

@media only screen and (min-width: 1024px) {
  .sidebar-width {
    width: 320px;
  }
  .content-width {
    width: calc(100vw - 320px);
  }
}

.button-icon-on-detail-page {
  size: 24px;
}

.chakra-divider {
  border-color: black !important;
}

.bg-table-list-color {
  background-color: #f6fcfc !important;
}

.inset-conversion-modal {
  inset: 0px 0px 0px 64px;
}

@media only screen and (min-width: 1024px) {
  .inset-conversion-modal {
    inset: 0px 0px 0px 320px;
  }
}

.swal2-container {
  z-index: 9999;
}

.image-detail-product {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.no-data-image-admin {
  margin-left: auto;
}
